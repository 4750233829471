@use "../../assets/scss/utils/" as *;

.notification {
  padding: 4rem 2rem;
  p {
    font-size: 1.5rem;
    font-weight: 500;
    color: $dark_gray_color;
  }
}
