@use "../../assets/scss/utils/" as *;

.main_layout {
  display: flex;
  gap: 2.5rem;

  .content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - $sidebar_width - 2.5rem);
    height: 100vh;
    overflow-y: scroll;
  }

  .small {
    width: calc(100vw - $sidebar_small_width - 2.5rem);
  }
}

@media screen and (max-width: 1700px) {
  .main_layout {
    gap: 2rem;

    .content {
      width: calc(100vw - $sidebar_medium_width - 2.5rem);
    }

    .small {
      width: calc(100vw - $sidebar_small_width - 2.5rem);
    }
  }
}

@media screen and (max-width: 850px) {
  .main_layout {
    gap: 2rem;

    .content,
    .small {
      width: 100vw;
    }
  }
}
